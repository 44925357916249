@mixin border-box() {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin content-box() {
	-webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transform($params) {
	-webkit-transform: $params;
	-moz-transform: $params;
	-o-transform: $params;
	-ms-transform: $params;
	transform: $params;
}

@mixin transition($params) {
  -moz-transition: $params;
  -ms-transition: $params;
  -o-transition: $params;
  -webkit-transition: $params;
  transition: $params;
}

@mixin box-shadow($params) {
  -moz-box-shadow: $params;
  -ms-box-shadow: $params;
  -o-box-shadow: $params;
  -webkit-box-shadow: $params;
  box-shadow: $params;
}

@mixin gradient($top, $bottom, $direction: to bottom) {
	background: $top;
	background: -webkit-linear-gradient($direction, $top 0%, $bottom 100%);
	background: -moz-linear-gradient($direction, $top 0%, $bottom 100%);
	background: -o-linear-gradient($direction, $top 0%, $bottom 100%);
	background: -ms-linear-gradient($direction, $top 0%, $bottom 100%);
	background: linear-gradient($direction, $top 0%, $bottom 100%);
}

@mixin alphaGradient($top: 3%, $bottom: 10%, $direction: to bottom) {
	$top: darken(white, $top);
	$bottom: darken(white, $bottom);
	background: $top;
	background: -webkit-linear-gradient($direction, $top 0%, $bottom 100%);
	background: -moz-linear-gradient($direction, $top 0%, $bottom 100%);
	background: -o-linear-gradient($direction, $top 0%, $bottom 100%);
	background: -ms-linear-gradient($direction, $top 0%, $bottom 100%);
	background: linear-gradient($direction, $top 0%, $bottom 100%);
}

@mixin verticalSideShadow($side: 5%,$center: 0%) {
	$side: darken(white, $side);
	$center: darken(white, $center);
	background: #f7f7f7;
	background: -webkit-linear-gradient(to bottom, $side 0%,$center 30%,$center 70%,$side 100%);
	background: -moz-linear-gradient(to bottom, $side 0%,$center 30%,$center 70%,$side 100%);
	background: -o-linear-gradient(to bottom, $side 0%,$center 30%,$center 70%,$side 100%);
	background: -ms-linear-gradient(to bottom, $side 0%,$center 30%,$center 70%,$side 100%);
	background: linear-gradient(to bottom, $side 0%,$center 30%,$center 70%,$side 100%);
}

@mixin iconForPosition($x, $y, $img: "../img/sprite.png") {
	background: transparent url($img) $x $y no-repeat;
}
