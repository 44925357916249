@import "settings";
@import "mixins";

html { background: #fff; }
body { background: #fff; }
html, body, input, textarea, select {
  color: #5c5f69;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
	font-weight: 400;
  line-height: normal;
}

*, *:after, *:before { @include border-box(); }

.content-wrapper {
  margin: 0 auto;
  overflow: hidden;
  &:after{
    clear: both;
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
  }
}

a {
  color: #000;
  outline: 0;
  text-decoration:none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

strong { font-weight: 700; }
p { margin-bottom: 20px; }
ul, ol { margin: 0; padding: 0; }
li { list-style-type: none; }

hr {
	@include content-box();
  height: 0;
}

input[type="checkbox"], input[type="radio"] {
	@include border-box();
  padding: 0;
}


/*****************************************************************************
                                CSS Clasess
*****************************************************************************/

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix { display: inline-block; }

html[xmlns] .clearfix { display: block; }

* html .clearfix { height: 1%; }

.cleared {
  display: block;
  clear: both;
  line-height: 0;
  height: 0;
}

.rs {
  float: right;
}
.ls {
  float: left;
}

.progress {
  height: 8px;
  @include border-radius(3px);
  background-color: #9e9e9e;
  .progress-bar {
    background-color: #b3cf82;
  }
}

/*****************************************************************************
                                 Colors Product
*****************************************************************************/
.color-1 { background-color: $color-1 !important; }
.color-2 { background-color: $color-2 !important; }
.color-3 { background-color: $color-3 !important; }
.color-4 { background-color: $color-4 !important; }
.color-5 { background-color: $color-5 !important; }
.color-6 { background-color: $color-6 !important; }
.color-7 { background-color: $color-7 !important; }
.color-8 { background-color: $color-8 !important; }
.color-9 { background-color: $color-9 !important; }
.color-10 { background-color: $color-10 !important; }
.color-11 { background-color: $color-11 !important; }
.color-12 { background-color: $color-12 !important; }


/*****************************************************************************
                                 Headlines
*****************************************************************************/

h1, h2, h3, h4, h5, h6 {
  display: block;
  margin:0;
}

h2.h2-main {
  color: #1c1c1c;
  font-family: 'Playfair Display', serif;
  font-size: 42px;
  a {
    color: #1c1c1c;
  }
}
h2.h2-sb {
  color: #1c1c1c;
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  a {
    color: #1c1c1c;
  }
}
h6.h6-main {
  margin-top: 6px;
  color: #b0afaf;
  font-size: 17px;
  line-height: 24px;
  font-weight: 300;
  text-transform: uppercase;
  a {
    color: #b0afaf;
  }
}

.text-line {
  display: flex;
  align-items: center;
  h2 {
    margin-right: 30px;
  }
  h2.h2-sb {
    margin-right: 6px;
    margin-bottom: 6px;
  }
  .line {
    flex: 1;
    height: 2px;
    background-color: #e7e7e7;
  }
  a.link-simple {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1429px) {
  h2.h2-main {
    font-size: 36px;
  }
  h6.h6-main {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1199px) {
  h2.h2-main {
    font-size: 32px;
  }
  h6.h6-main {
    font-size: 16px;
  }
  .text-line {
    h2 {
      margin-right: 20px;
    }
    a.link-simple {
      margin-left: 20px;
    }
  }
}
@media only screen and (max-width: 991px) {
  h2.h2-main {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  h2.h2-main {
    font-size: 22px;
  }
  h6.h6-main {
    font-size: 14px;
  }
  .text-line {
    h2 {
      margin-right: 15px;
    }
    a.link-simple {
      margin-left: 15px;
    }
  }
}
@media only screen and (max-width: 479px) {
  h2.h2-main {
    font-size: 20px;
  }
  h6.h6-main {
    margin-top: 4px;
    font-size: 13px;
  }
  .text-line {
    h2 {
      margin-right: 10px;
    }
    a.link-simple {
      margin-left: 10px;
    }
    .line {
      height: 1px;
    }
  }
}

/*****************************************************************************
                                 Buttons & Links
*****************************************************************************/
.btn {
  cursor: pointer;
  @include border-radius(3px);
  @include transition(all 0.3s ease);
}

.btn-transparent {
  padding: 0 35px;
  line-height: 48px;
  color: #fff;
  border: 2px solid #fff;
  font-size: 17px;
  font-weight: 400;
  &:hover, &:focus {
    outline: none;
    color: #222;
    background-color: #fff;
  }
}

.btn-transparent-green {
  padding: 0 35px;
  line-height: 48px;
  color: #222;
  border: 2px solid #0d716b;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  span {
    padding-right: 29px;
    background: url(../img/icons/arrow-right.png) no-repeat right center;
    @include transition(all 0.3s ease);
  }
  &:hover, &:focus {
    outline: none;
    background: #0d716b;
    color: #fff;
    span {
      background: url(../img/icons/arrow-right-white.png) no-repeat right center;
    }
  }
}

.btn-transparent-grey {
  padding: 11px 15px;
  line-height: 18px;
  color: #b2b2b2 !important;
  border: 2px solid #b2b2b2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff !important;
    background-color: #b2b2b2;
  }
}

.btn-transparent-black {
  padding: 11px 15px;
  line-height: 18px;
  color: #454343 !important;
  border: 2px solid #666;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff !important;
    background-color: #666;
  }
}

.btn-turquoise {
  padding: 11px 15px;
  line-height: 18px;
  color: #fff !important;
  border: 2px solid #07aba3;
  background: #07aba3;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff !important;
    background-color: #038a83;
    border-color: #038a83;
  }
}

.btn-transparent-turquoise {
  padding: 11px 15px;
  line-height: 18px;
  color: $color-links !important;
  border: 2px solid $color-links;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff !important;
    background-color: $color-links;
    border-color: $color-links;
  }
}

.btn-red {
  padding: 0 68px;
  line-height: 62px;
  color: $color-red;
  border: 3px solid $color-red;
  font-size: 19px;
  font-weight: 600;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: $color-red;
  }
}

.btn-grey {
  padding: 0 10px;
  line-height: 44px;
  color: #fff;
  border: 0;
  background-color: #acb2b8;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    outline: none;
    background-color: #93979b;
  }
}

.btn-link {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 14px 40px 14px 63px;
  color: #5d5c5c;
  font-weight: 400;
  font-size: 17px;
  border: 0;
  text-decoration: none;
  @include transition(all 0.3s ease);
  span {
    padding-right: 23px;
    background: url(../img/icons/arrow-right.png) no-repeat right center;
  }
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #343232;
    @include transition(all 0.3s ease);
  }
  &:hover {
    color: $color-links;
    text-decoration: none;
    &:after {
      background: $color-links;
    }
  }
}

.btn-black {
  padding: 15px 25px;
  line-height: 18px;
  color: #fff !important;
  border: 0 solid #000;
  background: #000;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff !important;
    background-color: $color-links;
    border-color: $color-links;
  }
}

a.link {
  padding-right: 25px;
  color: #222;
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  text-transform: uppercase;
  background: url(../img/icons/arrow-right.png) no-repeat right center;
  @include transition(all 0.4s ease);
  &:hover {
    color: $color-links;
  }
}

a.link-simple {
  position: relative;
  display: inline-block;
  color: #353535;
  font-size: 13px;
  line-height: 23px;
  font-weight: 500;
  text-transform: uppercase;
  @include transition(all 0.4s ease);
  &:after {
    position: absolute;
    bottom: -11px;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #e7e7e7;
    @include transition(all 0.4s ease);
  }
  &:hover {
    color: $color-links;
    &:after {
      background: $color-links;
    }
  }
}

@media only screen and (max-width: 767px) {
  a.link-simple:after {
    bottom: -7px;
  }
  .btn-link {
    padding: 14px 40px 10px 40px;
    font-size: 16px;
  }
}

/*****************************************************************************
                                 Error 404
*****************************************************************************/
#info-error {
  text-align: center;
  margin: auto;
  a.logo {
    margin: 30px auto 80px;
    display:block;
    width: 176px;
    height: 73px;
    background: url(../img/logo.png) no-repeat 0 0;
    background-size: 100% auto;
    text-indent: -9999px;
    @include transition(all 0.4s ease);
  }
  h3 {
    margin: 0px 0 31px 0;
    color: $color-links;
    line-height: 190px;
    font-size: 200px;
    font-weight: 900;
  }
  h4 {
    margin-bottom: 14px;
    color: #121212;
    line-height: 54px;
    font-size: 44px;
    font-weight: 900;
  }
  p {
    margin-bottom: 36px;
    color: #121212;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
  }
  a.btn-back {
    display: inline-block;
    padding: 0 32px;
    background: $color-links;
    border: 2px solid $color-links;
    color: #fff;
    line-height: 63px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    @include border-radius(3px);
    @include transition(all 0.4s ease);
    &:hover {
      background: none;
      color: $color-links;
    }
  }
  .footer {
    margin-top: 100px;
    padding: 0 30px;
    padding-bottom: 0px;
    color: #999;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 20px;
    a {
      color: #4e4c4c;
      @include transition(all 0.4s ease);
      &:hover {
        color: $color-links;
      }
    }
  }
}
@media only screen and (max-width: 1429px) {
  #info-error {
    h3 {
      margin-bottom: 0;
      font-size: 170px;
    }
  }
}
@media only screen and (max-width: 1199px) {
  #info-error {
    a.logo {
      margin-bottom: 60px;
    }
    h3 {
      font-size: 150px;
      line-height: 150px;
    }
    h4 {
      font-size: 30px;
    }
  }
  #info-error .footer span {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  #info-error {
    h3 {
      margin-bottom: 0;
      font-size: 90px;
      line-height: 90px;
    }
    h4 {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: 575px) {
  #info-error {
    a.logo {
      margin: 20px auto 30px;
    }
    h3 {
      font-size: 80px;
      line-height: 80px;
    }
    h4 {
      font-size: 20px;
      line-height: inherit;
    }
    a.btn-back {
      padding: 0 25px;
      line-height: 58px;
    }
  }
}

/*****************************************************************************
                                 Pagination
*****************************************************************************/
ul.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  li {
    color: #222;
    font-size: 15px;
    font-weight: 300;
    a {
      display: block;
      padding: 3px 5px;
      color: #222;
      @include transition(all 0.3s ease);
      &.active {
        color: $color-links;
        font-weight: 500;
      }
      &:hover {
        color: $color-links;
      }
    }
    &.prev a {
      margin-right: 10px;
      width: 20px;
      height: 29px;
      background: url(../img/icons/arrow-left-small.png) no-repeat center center;
      text-indent: -9999px;
    }
    &.next a {
      margin-left: 10px;
      width: 20px;
      height: 29px;
      background: url(../img/icons/arrow-right-small.png) no-repeat center center;
      text-indent: -9999px;
    }
  }
}
.btn-pagination {
  display: block;
  cursor: pointer;
  @include border-radius(3px);
  @include transition(all 0.3s ease);
  padding: 9px 15px;
  line-height: 16px;
  color: #fff;
  border: 0;
  background-color: $color-links;
  font-size: 16px;
  font-weight: 400;
  &:hover, &:focus, &.active {
    color: #fff;
    outline: none;
    background-color: #7c5623;
  }
}
@media only screen and (max-width: 1199px) {
  ul.pagination {
    margin: 25px 0;
  }
}
@media only screen and (max-width: 991px) {
  ul.pagination {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 479px) {
  ul.pagination {
    li.prev {
      .btn-pagination {
        padding: 9px 20px;
        background: $color-links url(../img/icons/prev.png) no-repeat center center;
        text-indent: -9999px;
        &:hover {
          background-color: #7c5623;
        }
      }
    }
    li.next {
      .btn-pagination {
        padding: 9px 20px;
        background: $color-links url(../img/icons/next.png) no-repeat center center;
        text-indent: -9999px;
        &:hover {
          background-color: #7c5623;
        }
      }
    }
  }
}

/*****************************************************************************
                                 Site Map
*****************************************************************************/
ul.site-map {
  padding-left: 35px;
  padding-top: 10px;
  li {
    position: relative;
    list-style-type: none;
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 500;
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 9px;
      left: -23px;
      background-color: $color-links;
      width: 8px;
      height: 8px;
    }
    a {
      color: #040c26;
      &:hover {
        color: $color-links;
      }
    }
    ul {
      padding-left: 30px;
      padding-top: 10px;
      li {
        font-size: 16px;
        font-weight: 300;
        &:after {
          top: 10px;
          left: -15px;
          width: 5px;
          height: 5px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  ul.site-map li {
    font-size: 16px;
  }
}

/*****************************************************************************
                                 Article Typography
*****************************************************************************/
article {
  * a {
    color: $color-links;
    &:hover {
      color: #000;
    }
  }
  h2 {
    color: #222;
    font-size: 32px;
    line-height: 42px;
    margin: 0 0 30px;
    font-weight: 600;
  }
  h3 {
    color: #222;
    font-size: 28px;
    margin: 0 0 30px;
    padding-top: 20px;
    font-weight: 600;
  }
  h4 {
    color: #222;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 30px;
    padding-top: 20px;
  }
  h5 {
    color: #222;
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0;
  }
  p {
    margin-bottom: 20px;
    color: #222;
    font-size: 19px;
    line-height: 34px;
    font-weight: 300;
    strong {
      font-weight: 600;
    }
    a {
      color: $color-links;
      &:hover {
        color: $color-links;
        text-decoration: underline;
      }
    }
  }
  ol {
    margin-left: 23px;
    li {
      list-style: decimal;
      padding-left: 12px;
      margin: 0 0 20px;
      color: #222;
      font-size: 19px;
      line-height: 34px;
      font-weight: 300;
    }
    ol {
      padding-top: 10px;
      li {
        list-style-type: lower-latin;
      }
    }
  }
  ul {
    margin-left: 14px;
    margin-bottom: 30px;
    li {
      position: relative;
      display: block;
      padding-left: 19px;
      padding-bottom: 10px;
      color: #222;
      font-size: 19px;
      line-height: 34px;
      font-weight: 300;
      &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        display: block;
        width: 5px;
        height: 5px;
        background-color: $color-links;
        @include border-radius(50%);
      }
    }
  }
  ol ul, ol ol, ul ul, ul ol {
    margin-top: 20px;
  }
  table {
    margin: 10px 0 30px !important;
    font-size: 17px;
    line-height: 27px;
    font-weight: 300;
  }
}
@media only screen and (max-width: 1429px) {
  article {
    p, ol li, ul li {
      font-size: 17px;
      line-height: 32px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  article {
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
    h3 {
      font-size: 26px;
    }
    h4 {
      font-size: 22px;
    }
    h5 {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 991px) {
  article {
    h2 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
    }
    h3 {
      font-size: 22px;
      margin: 0 0 20px;
      padding: 15px 0 0;
    }
    h4 {
      font-size: 20px;
      margin: 0 0 20px;
      padding: 15px 0 0;
    }
    h5 {
      font-size: 18px;
      margin: 20px 0;
    }
    p, table, ul li, ol li {
      font-size: 16px;
      line-height: 30px;
    }
    ul li::before {
      top: 13px;
    }
    ol li {
      padding-left: 10px;
    }
  }
}
@media only screen and (max-width: 767px) {
  article {
    h2 {
      font-size: 22px;
      line-height: 28px;
    }
		h3 {
			font-size: 20px;
		}
		h4 {
			font-size: 18px;
		}
		h5 {
			font-size: 17px;
		}
		p, ol li, ul li, table {
			font-size: 15px;
			line-height: 26px;
		}
		p, ul, ol {
			margin-bottom: 15px;
		}
    ol li {
      margin-bottom: 15px;
    }
	}

}
@media only screen and (max-width: 575px) {
  article {
    p br {
      display: none;
    }
  }
}
@media only screen and (max-width: 479px) {
  article {
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
		h3 {
			padding-top: 0;
			margin-bottom: 15px;
			font-size: 18px;
		}
		h4 {
			padding-top: 0;
			margin-bottom: 15px;
			font-size: 16px;
		}
		h5 {
			padding-top: 0;
			margin-bottom: 15px;
			font-size: 15px;
		}
		ol {
			margin-left: 15px;
			li {
				padding-left: 8px;
        ul li {
          padding-left: 14px;
        }
			}
		}
		ul {
			margin-left: 0;
		}
	}
}

/*****************************************************************************
Forms
*****************************************************************************/
.select2-container {
  width: 213px !important;
}
.select2-container .select2-selection--single {
  height: 40px;
}
.select2-container--default .select2-selection--single {
  @include border-radius(3px);
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #222;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 40px;
  font-weight: 300;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 40px;
  height: 38px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  width: 20px;
  height: 13px;
  border: 0;
  margin-left: -10px;
  margin-top: -6px;
  background: url(../img/icons/arrow-down-big.png) no-repeat 0 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  background: url(../img/icons/arrow-up-big.png) no-repeat 0 0;
  border: 0;
}
.select2-results__option {
  color: #222;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
}

.form-simple {
  .name {
    position: absolute;
    top: -7px;
    left: 7px;
    z-index: 9;
    padding: 0 3px;
    background-color: #fff;
    color: #767676;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 15px;
  }
  .form-group {
    position: relative;
    margin-bottom: 25px;
  }
  .form-row-ad {
    display: flex;
    justify-content: space-between;
    .form-group {
      width: 49%;
    }
  }
  .form-control {
    padding: 11px 12px;
    height: 44px;
    border-color: #ccc;
    background-color: #fff;
    color: #222;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 17px;
    @include border-radius(2px);
    &.is-invalid {
      border-bottom: 2px solid #dc3545;
      background-image: none;
    }
    &:focus {
      border-color: $color-links;
      box-shadow: 0 0 0 0 rgba(224, 29, 59, 0.3) !important;
    }
  }
  .invalid-feedback {
    position: absolute;
    bottom: -16px;
    left: 0;
  }
  textarea.form-control {}
  .custom-checkbox {
    .custom-control-label {
      padding: 5px 0 0 5px;
      color: #818692;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
  }
  .custom-file {
    .custom-file-input {
      padding: 20px 18px !important;
      height: 49px !important;
      border-color: #c9cacf !important;
      background-color: #fafafa !important;
      color: #747272;
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      @include border-radius(2px);
    }
  }
  .file {
    margin-bottom: 20px;
    margin-left: 25px;
    .attach-file {
      padding: 0 0 0 31px;
      background: url(../img/icons/attach-file.png) no-repeat 0 0;
      color: #747272;
      line-height: 30px;
      font-size: 15px;
      font-weight: 400;
      @include transition(all 0.3s ease);
    }
  }
  .form-check-input {
    margin-top: 0;
  }
  .form-check-label {
    color: #222;
    font-size: 13px;
    line-height: 13px;
    font-weight: 300;
    a {
      color: $color-links;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .radio {
    display: flex;
    padding-left: 20px;
    input[type="radio"] {
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
    label {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0;
      padding-left: 5px;
      width: 100%;
      color: #222;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      cursor: pointer;
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 17px;
        height: 17px;
        left: 0;
        margin-left: -20px;
        border: 1px solid #cccccc;
        @include border-radius(50%);
        background-color: #fff;
        -webkit-transition: border 0.15s ease-in-out;
        -o-transition: border 0.15s ease-in-out;
        transition: border 0.15s ease-in-out;
      }
      &:after {
        display: inline-block;
        position: absolute;
        content: " ";
        width: 11px;
        height: 11px;
        left: 3px;
        top: 3px;
        margin-left: -20px;
        border-radius: 50%;
        background-color: #555555;
        @include transform(scale(0, 0));
        -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      }
    }
  }
  .radio-success input[type="radio"]:checked + label::after {
    background-color: #5cb85c;
    @include transform(scale(1, 1));
  }
  .radio-success input[type="radio"]:checked + label::before {
    border-color: #5cb85c;
  }
  .best-checkbox {
    position: relative;
    width: 100%;
    cursor: pointer;
    label {
      margin: 0;
      width: 100%;
      color: #222;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2000;
        width: 100%;
        height: 26px;
        margin: -4px 0 0;
        padding: 0;
        border: 0;
        opacity: 0;
        cursor: pointer;
      }
      .new-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        span.square {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid #aaa;
          background-color: #fff;
          @include border-radius(2px);
        }
        span.text {
          padding-left: 29px;
          color: #222;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
        }
        &.selected {
          span.square {
            border: 2px solid $color-links;
            background: #fff url(../img/icons/selected-checkbox.png) no-repeat center center;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .form-simple .form-group {
		margin-bottom: 20px;
	}
}
@media only screen and (max-width: 575px) {

}

/*****************************************************************************
                                 Menu Hamburger Styles
*****************************************************************************/

/* Icon 1 */

#nav-icon {
    width: 26px;
    height: 19px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

/* Icon 2 */

#nav-icon {
  //display: none;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: #333;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 0px;
}

#nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 7px;
}

#nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    top: 14px;
}

#nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
    left: 5px;
    top: 7px;
}

#nav-icon.open span:nth-child(2) {
    left: calc(50% - 2px);
    top: 7px;
}

#nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}

#nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}

#nav-icon.open span:nth-child(5) {
    left: 5px;
    top: 13px;
}

#nav-icon.open span:nth-child(6) {
    left: calc(50% - 2px);
    top: 13px;
}
