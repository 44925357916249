$color-links: #07aba3;
$color-red: #ce0000;
$color-turquoise: #08aca3;

$color-1: #f7ecd0;
$color-2: #c3bc9a;
$color-3: #735b5b;
$color-4: #ab0c0c;
$color-5: #913fe5;
$color-6: #60b920;
$color-7: #2b4e7a;
$color-8: #898989;
$color-9: #000;
$color-10: #b93026;
$color-11: #c8a2c8;
$color-12: #891721;
